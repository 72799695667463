<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Laporan <b>Stock Opname</b> Klinik IONA
      </div>
      
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <div class="row">
              
                <div class="col-md-6">
                  <button class="btn mx-1 btn-timeseries " @click="btnActiveOnClick('days')"  v-bind:class="{'btn-info' : btnActive.days, 'btn-outline-info' : btnActive.days == false   }">Hari Ini</button>
                  <button class="btn mx-1 btn-timeseries " @click="btnActiveOnClick('month')"  v-bind:class="{'btn-info' : btnActive.month, 'btn-outline-info' : btnActive.month == false  }">Bulan Ini</button>
                  <button class="btn mx-1 btn-timeseries " @click="btnActiveOnClick('lastMonth')"  v-bind:class="{'btn-info' : btnActive.lastMonth, 'btn-outline-info' : btnActive.lastMonth == false  }">Bulan Kemarin</button>
                  <button class="btn mx-1 btn-timeseries " @click="btnActiveOnClick('year')"  v-bind:class="{'btn-info' : btnActive.year, 'btn-outline-info' : btnActive.year == false  }">Tahun Ini</button>
                  <button class="btn mx-1 btn-timeseries "  v-bind:class="{'btn-info' : btnActive.period, 'btn-outline-info' : btnActive.period == false  }" @click="btnPeriodeOnClick">Periode</button>
                </div>

            </div>

            <div class="row mt-2">
              <div class="col-md-6" v-if="onShowPeriod">
                <span class="d-none d-sm-block">
                  <b-input-group>

                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Tanggal Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Tanggal Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <template #append>
                      <b-button squared @click="generalFilter" variant="success"
                        >Cari</b-button
                      >
                      <b-button squared @click="resetFilter" variant="danger"
                        >Reset</b-button
                      >
                    </template>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                      
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Tanggal Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Tanggal Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                         <template #append>
                          <b-button squared @click="resetFilter" variant="danger"
                            >Reset</b-button
                          >
                          <b-button squared @click="generalFilter" variant="success"
                            >Cari</b-button
                          >
                        </template>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
            </div>

            <b-table striped hover :items="items" :fields="fields">
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '20%' : '' }"
                />
              </template>
              <template #cell(count)="data">
                <span>{{data.item.count}} item</span>
              </template>
              <template #cell(quantityPlus)="data">
                <span>{{ parseInt(data.item.quantityPlus).toLocaleString('id-ID') }} item</span>
              </template>
              <template #cell(quantityMinus)="data">
                <span>{{ parseInt(data.item.quantityMinus).toLocaleString('id-ID') }} item</span>
              </template>
              <!-- <template #cell(amount)="data">
                <span>{{ parseInt(data.item.amount).toLocaleString('id-ID') }}</span>
              </template> -->
              <template #cell(actions)="data">
                
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="
                    $router.push({ path: '/stock-opname-report/detail/' + data.item.id })
                  "
                >
                  <i class="fas fa-eye px-0"></i>
                </b-button>
               
              </template>
            </b-table>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment'

export default {
  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        start_date: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      },
       // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days : true,
        month : false,
        lastMonth : false,
        year : false,
        period : false,
      },
      lastPeriodType:"days",
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "checking_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "employee_name",
          label: "Petugas",
          sortable: true,
        },
        {
          key: "count",
          label: "Item(Jml)",
          sortable: true,
        },
        // {
        //   key: "status",
        //   label: "Status",
        //   sortable: true,
        // },
        {
          key: "quantityPlus",
          label: "Selisih(+)",
          sortable: true,
        },
        {
          key: "quantityMinus",
          label: "Selisih(-)",
          sortable: true,
        },
        
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      blood_type: [],
      // User Access
      btn: false,
    };
  },

  methods: {
    pageOnClick(page) {
      this.currentPage = page;
      this.pagination();
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete("stock-opnames/" + id);
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

    resetFilter() {
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.btnActiveOnClick(this.lastPeriodType)
      this.pagination()
    },

    async pagination() {
      let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        `stock-opname-report`,
        `${filterParams}`
      );
      
      this.items = response.data;
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("donors/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    

    filterByName() {
      // this.pagination();
      this.generalFilter()
    },

    filterByBloodType(evt) {
      this.filter.blood_type_id = evt.id
      this.generalFilter()
    },

    generalFilter(){
      this.pagination()
    },

    setActiveButton(){
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for(a = 0; a < access_right.length; a++){
        if(access_right[a] == "2202"){
          this.btn = true
        }
      }
    },

    btnPeriodeOnClick(){
      if(this.onShowPeriod == false){
        this.onShowPeriod = true
        this.btnActiveOnClick('period')
      }else{
        this.onShowPeriod = false
        this.btnActiveOnClick('period')
      }
    },

    btnActiveOnClick(type){
      if(type == 'days'){
        // set time
        this.filter.start_date = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()
        
        // set btn Active
        this.btnActive.days = true
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "days"

        // hide period
        this.onShowPeriod = false
      }else if(type == 'month'){
        // set time
        this.filter.start_date = moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = true
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "month"

        // hide period
        this.onShowPeriod = false
      }else if(type == 'lastMonth'){
        // set time
        this.filter.start_date = moment().subtract(1,'months').startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().subtract(1,'months').endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')


        // get data
        this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = true
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "lastMonth"

        // hide period
        this.onShowPeriod = false
      }else if(type == 'year'){
        // set time
        this.filter.start_date = moment().startOf('year').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('year').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = true
        this.btnActive.period = false

        this.lastPeriodType = "year"

        // hide period
        this.onShowPeriod = false
      }else if(type == 'period'){
        // set time
        this.filter.start_date = ""
        this.filter.end_date = ""
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = true
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laporan Stock Opname", route: "/stock-opname-report" },
      { title: "Daftar Stock Opname" },
    ]);

    this.pagination();
    // this.getBloodTypeOption();
    this.setActiveButton()
  },

  // watch: {
  //   'filter.blood_type_id': function (newVal, oldVal) {
  //     if (typeof newVal === 'undefined') {
  //       this.filter.blood_type_id = ''
  //       this.generalFilter()
  //     }
  //   }
  // },
};
</script>

<style>
</style>